<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <!-- <span v-my-loading>设备清单</span> -->
        <span>版本列表</span>
        <el-button @click="add" class="btn" type="primary" size="mini"
          :disabled="roleId == 2 || roleId == 3 || roleId == 4">新增版本</el-button>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="apk名称"></el-table-column>
          <el-table-column prop="packageName" label="包名称" width="120"></el-table-column>
          <el-table-column prop="versionName" label="版本名称" width="100"></el-table-column>
          <el-table-column prop="versionCode" label="版本号" width="80"></el-table-column>
          <el-table-column prop="apkUrl" label="版本地址" width="300"></el-table-column>
          <el-table-column prop="force" label="是否强制升级" width="110">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.force == 1" size="small" type="success">是</el-tag>
              <el-tag v-else size="small" type="danger">否</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建日期" width="160">
            <template slot-scope="scope">
              {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
          <el-table-column prop="updateLog" label="更新日志"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                  <!-- <el-dropdown-item><div @click="scrap(scope.row)">报废</div></el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [],
      isChangeOpen: 0,
      isDetailOpen: 0,
      isEditOpen: 0,
      sheshi: [],
      bumen: [],
      leixin: [],
      yuangong: [],
      detailRow: {},
      editRow: {},
      roleId: 0
    };
  },
  methods: {
    dayjs,
    dlt(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('otaDelete', {}, {}, row.id).then(res => {
          this.loadListData()
        })
      })
    },
    add() {
        this.$router.push('/apkManage/add')
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 5
    },
    loadListData() {
      this.loading = true
      this.$ajax.post('otaQuery', {
        pageNum: this.page,
        pageSize: this.size
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadListData()
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>